import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import { ReactNode } from 'react'

import Search, { SearchProps } from '../Search'

export interface ContentLayoutProps {
  title: string
  subtitle: string
  SearchProps?: SearchProps
  showSearch?: boolean
  children?: ReactNode
  callToAction?: ReactNode
}

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'none',
  },
}))

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
  },
}))

const ContentLayout = ({
  title,
  subtitle,
  SearchProps: searchProps,
  showSearch = true,
  callToAction,
  children,
}: ContentLayoutProps) => {
  const theme = useTheme()
  return (
    <Container
      sx={{
        height: '100%',
        pt: { md: theme.spacing(5), xs: theme.spacing(0) },
        pb: { md: theme.spacing(0), xs: theme.spacing(8) },
        overflow: 'auto',
        width: '100%',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      className="Content-Layout"
    >
      <Grid container spacing={2}>
        <Grid item md={showSearch ? 6 : 12} xs={12} m={0}>
          <Title variant="h3">{title}</Title>
          <Typography variant="body1" color="text.secondary">
            {subtitle}
          </Typography>
          {callToAction && <Box mt={4}>{callToAction}</Box>}
        </Grid>
        {showSearch && (
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              [theme.breakpoints.down('md')]: {
                margin: theme.spacing(0, 2),
              },
            }}
          >
            <SearchContainer>
              <Search {...searchProps} />
            </SearchContainer>
          </Grid>
        )}
        <Grid item mt={2} md={12} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  )
}
export default ContentLayout
