import { useQuery } from '@apollo/client'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useUpdatePermissions from '@/hooks/permissions/useUpdatePermissions'

import { IS_LOGGED_IN } from './queries'

interface AuthRouteProps {
  children: ReactNode
}

export interface SignInQueryResult {
  isLoggedIn: boolean
}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const location = useLocation()
  const { data } = useQuery<SignInQueryResult>(IS_LOGGED_IN)
  useUpdatePermissions({ isLoggedIn: Boolean(data?.isLoggedIn) })

  if (data?.isLoggedIn && location.pathname === '/signIn') {
    return <Navigate state={{ from: location }} to="/myReferrals" />
  }

  if (!data?.isLoggedIn && !(location.pathname === '/signIn')) {
    return <Navigate state={{ from: location }} to="/signIn" />
  }

  return children
}

export default AuthRoute
