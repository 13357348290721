import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import JTextField from '@/components/atoms/JTextField'
import { isValidEmail } from '@/utils/helpers'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import useTheme from '@mui/material/styles/useTheme'
import useInviteToNetworkModal from './useInviteToNetworkModal'

export interface InviteAttorneyModalProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const InviteToNetworkModal = ({
  open,
  onClose,
  onSubmit,
}: InviteAttorneyModalProps) => {
  const theme = useTheme()

  const {
    handleSubmit,
    onSendInvitation,
    loading,
    fields,
    register,
    control,
    append,
    errors,
    watch,
    reset,
  } = useInviteToNetworkModal({
    onSubmit,
  })

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <ConfirmationModal
      fullWidth
      maxWidth="md"
      open={open}
      title="Invite Your Lawyer Network to Attorney Share"
      AcceptButtonProps={{
        children: 'Send Invitations',
        onClick: handleSubmit(onSendInvitation),
        disabled: loading,
      }}
      onClose={handleClose}
    >
      <Typography mb={4}>
        Can&apos;t find your referral partners on the platform? Invite them to
        Attorney Share today and start sending and receiving cases. There is no
        cost for them to sign up.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 3, sm: 1 },
        }}
        component="form"
        onSubmit={handleSubmit(onSendInvitation)}
      >
        {fields.map((f, i) => {
          const { name: email } = register(`attorneys.${i}.email`)
          const { name: firstName } = register(`attorneys.${i}.firstName`)
          const { name: lastName } = register(`attorneys.${i}.lastName`)

          return (
            <Grid
              container
              key={f.id}
              columns={4}
              rowSpacing={1}
              columnSpacing={{
                xs: 1,
                md: 2,
              }}
            >
              <Grid item xs={2} sm={1}>
                <JTextField
                  name={firstName}
                  control={control}
                  label="First Name"
                  type="text"
                  rules={{
                    validate: {
                      required: value => {
                        const emailValue = watch(`attorneys.${i}.email`)
                        const firstNameValue = String(value)
                        const lastNameValue = watch(`attorneys.${i}.lastName`)

                        const rowIsEmpty =
                          emailValue === '' &&
                          firstNameValue === '' &&
                          lastNameValue === ''

                        return (
                          rowIsEmpty ||
                          firstNameValue !== '' ||
                          'First name is required'
                        )
                      },
                    },
                  }}
                  error={!!errors.attorneys?.[i]?.firstName}
                />
              </Grid>
              <Grid item xs={2} sm={1}>
                <JTextField
                  name={lastName}
                  control={control}
                  label="Last Name"
                  type="text"
                  rules={{
                    validate: {
                      required: value => {
                        const emailValue = watch(`attorneys.${i}.email`)
                        const firstNameValue = watch(`attorneys.${i}.firstName`)
                        const lastNameValue = String(value)

                        const rowIsEmpty =
                          emailValue === '' &&
                          firstNameValue === '' &&
                          lastNameValue === ''

                        return (
                          rowIsEmpty ||
                          lastNameValue !== '' ||
                          'Last name is required'
                        )
                      },
                    },
                  }}
                  error={!!errors.attorneys?.[i]?.lastName}
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <JTextField
                  name={email}
                  control={control}
                  label="Attorney Email"
                  type="text"
                  rules={{
                    validate: {
                      required: value => {
                        const emailValue = String(value)
                        const firstNameValue = watch(`attorneys.${i}.firstName`)
                        const lastNameValue = watch(`attorneys.${i}.lastName`)

                        const rowIsEmpty =
                          emailValue === '' &&
                          firstNameValue === '' &&
                          lastNameValue === ''

                        return (
                          rowIsEmpty ||
                          isValidEmail(emailValue) ||
                          'Email is required'
                        )
                      },
                    },
                  }}
                  error={!!errors.attorneys?.[i]?.email}
                />
              </Grid>
            </Grid>
          )
        })}
        {/* NOTE: The presence of this hidden submit button enables the form to be submitted by pressing the enter key. */}
        <Button type="submit" sx={{ display: 'none' }}>
          Submit
        </Button>
      </Box>
      <Box mt={2.5}>
        <Button
          onClick={() => append({ email: '', firstName: '', lastName: '' })}
          startIcon={
            <PersonAddAlt1OutlinedIcon sx={{ fontSize: theme.spacing(1) }} />
          }
        >
          Add more invitations
        </Button>
      </Box>
    </ConfirmationModal>
  )
}

export default InviteToNetworkModal
