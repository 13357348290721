import { Channel, DefaultGenerics, StreamChat } from 'stream-chat'

export enum ChannelOptions {
  DirectMessages = 'direct',
  CaseMessages = 'case',
}

export const isChannelOption = (value: unknown): value is ChannelOptions =>
  typeof value === 'string' &&
  Object.values(ChannelOptions).includes(value as ChannelOptions)

export function assertIsChannelOption(
  value: unknown
): asserts value is ChannelOptions {
  if (!isChannelOption(value)) {
    throw new TypeError(`${JSON.stringify(value)} is not ChannelOptions`)
  }
}

export interface IChannels {
  [ChannelOptions.CaseMessages]: Channel<DefaultGenerics>[]
  [ChannelOptions.DirectMessages]: Channel<DefaultGenerics>[]
}

export function assertClient(
  client: StreamChat | null
): asserts client is StreamChat {
  if (client === null) {
    throw new TypeError('client is null')
  }
}
