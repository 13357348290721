/* eslint-disable import/no-cycle */
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useLocation, Link } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { useEffect, useState } from 'react'
import { useRestrictedUserPermissions } from '@/hooks/permissions/useRestrictedUserPermissions'
import { PATHS_WITH_SECTIONS } from '@/router/RoutesUtils'
import { getMyNetworkFirstLink } from '@/pages/MyNetwork/utils/constants'
import MyReferralsIcon from './Icons/MyReferralsIcon'
import MarketplaceIcon from './Icons/MarketplaceIcon'
import NetworkIcon from './Icons/NetworkIcon'
import AnalyticsIcon from './Icons/AnalyticsIcon'

const StyledLink = styled(Link)(
  ({ theme }) => `
  flex-grow: 1;
  ${theme.breakpoints.up('md')} {
    max-width: 200px;
  }
`
)

const StyledButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.palette.text.secondary};
  
  min-height: 64px;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  background-color: ${theme.palette.common.white};
  width: 100%;
  white-space: nowrap;
  flex-direction: column;
  &:hover {
    background-color: ${theme.palette.primary.lightest};
  }
  &:active,
  &:focus,
  &.active {
    background-color: ${theme.palette.secondary.lightest};
    border-bottom: 4px solid ${theme.palette.secondary.main};
    color: ${theme.palette.primary.main};
  }
  .MuiButton-startIcon {
    svg {
      font-size: 24px;
    }
  }
  ${theme.breakpoints.up('md')} {
    flex-direction: initial;
    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: ${theme.spacing(1)};
    }
  }
  ${theme.breakpoints.down('md')} {
    padding: 0;
    .MuiButton-startIcon {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-inline: 0px;
    }
  }
`
)

const Links = () => {
  const theme = useTheme()
  const location = useLocation()
  const { isRestrictedUser, currentPermissionsSet: inPermissions } =
    useRestrictedUserPermissions('Marketplace', 'inOrganization')
  const { currentPermissionsSet: outPermissions } =
    useRestrictedUserPermissions('Marketplace', 'outOrganization')

  const LinksData = [
    {
      label: 'My Referrals',
      icon: MyReferralsIcon,
      route: RoutesData.RMyReferrals.path,
      routesBase: [RoutesData.RMyReferrals.path],
    },
    {
      label: 'Marketplace',
      icon: MarketplaceIcon,
      route: RoutesData.RMarketplace.path,
      routesBase: [
        RoutesData.RMarketplace.path,
        PATHS_WITH_SECTIONS.CASE_WITH_ID,
      ],
    },
    {
      label: 'My Network',
      icon: NetworkIcon,
      route: getMyNetworkFirstLink(),
      routesBase: [PATHS_WITH_SECTIONS.MY_NETWORK],
    },
    {
      label: 'Analytics',
      icon: AnalyticsIcon,
      route: RoutesData.RAnalytics.path,
      routesBase: [RoutesData.RAnalytics.path],
    },
  ]

  const [menu, setMenu] = useState(LinksData)

  useEffect(() => {
    const data = LinksData.filter(f => {
      switch (f?.label) {
        case 'Marketplace':
          return !isRestrictedUser && outPermissions?.Marketplace?.read
        case 'My Referrals':
          return (
            !isRestrictedUser &&
            (outPermissions?.Purchase?.read ||
              inPermissions?.Case?.read ||
              outPermissions?.Case?.read ||
              inPermissions?.Agreements?.read)
          )
        case 'My Network':
          return (
            !isRestrictedUser &&
            (outPermissions?.User?.read || outPermissions?.Favorites?.read)
          )
        default:
          return true
      }
    })
    setMenu(data)
  }, [inPermissions, outPermissions, isRestrictedUser])

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        gap: 0,
        flexGrow: 1,
      }}
    >
      {inPermissions &&
        outPermissions &&
        menu.map(link => (
          <StyledLink key={link.label} to={link.route}>
            <StyledButton
              startIcon={
                <link.icon
                  filled={
                    Array.isArray(link.routesBase) &&
                    link.routesBase.some(subPath =>
                      location.pathname.startsWith(subPath)
                    )
                  }
                />
              }
              className={
                Array.isArray(link.routesBase) &&
                link.routesBase.some(subPath =>
                  location.pathname.startsWith(subPath)
                )
                  ? 'active'
                  : ''
              }
            >
              <Typography
                variant="subtitle1"
                sx={[
                  {
                    width: { xs: '100%', md: 'auto' },
                    display: { xs: 'flex', md: 'block' },
                    justifyContent: 'center',
                    ...(menu.length > 3 && {
                      fontSize: {
                        xs: '0.6rem',
                        sm: theme.typography.subtitle1.fontSize,
                      },
                    }),
                  },
                ]}
              >
                {link.label}
              </Typography>
            </StyledButton>
          </StyledLink>
        ))}
    </Box>
  )
}
export default Links
