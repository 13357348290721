import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface LoadingContextType {
  isLoading: boolean
  startLoading: () => void
  stopLoading: () => void
}

const initialContext: LoadingContextType = {
  isLoading: false,
  startLoading: () => {},
  stopLoading: () => {},
}

export const LoadingContext = createContext<LoadingContextType>(initialContext)

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }
  return context
}

/**
 * A hook that automatically manages loading state based on the condition and handles cleanup on unmount
 * @param condition The condition that determines whether loading should be active
 */
export const useLoadingEffect = (condition: boolean) => {
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    if (condition) {
      startLoading()
    } else {
      stopLoading()
    }
    // Cleanup function that runs when the component unmounts
    return () => {
      stopLoading()
    }
  }, [condition, startLoading, stopLoading])
}

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  const value = useMemo(
    () => ({
      isLoading,
      startLoading,
      stopLoading,
    }),
    [isLoading]
  )

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}
