import './App.css'

import { scan } from 'react-scan'
import { ApolloProvider } from '@apollo/client'
import { RouterProvider } from 'react-router-dom'

import rootRouter from '@/router/root'
import { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import initApollo from './apollo'
import { GlobalContextProvider } from './context/GlobalContext'
import { LoadingProvider } from './context/LoadingContext'
import { initializeDatadogRUM } from './utils/datadog'
import { OptimizelyProvider } from './optimizely/OptimizelyContext'
import { analyticsService } from './services/Analytics'
import { bootParamsService } from './services/BootParams'
import { ChatClientProvider } from './context/ChatClientContext'
import { SnackbarProvider } from './context/SnackbarContext'

const ENV = import.meta.env.VITE_VERCEL_ENV

if (ENV === 'development' || ENV === 'preview') {
  scan({
    enabled: true,
    animationSpeed: 'slow',
  })
}

initializeDatadogRUM()
analyticsService.initialize()
bootParamsService.initialize()

const App = () => {
  const client = initApollo()
  const queryClient = new QueryClient()
  // TODO: replace with refs for performance?
  const [preventNavigation, setPreventNavigation] = useState(false)
  const [selectedTab, setSelectedTab] = useState('')
  const [globalPermissions, setGlobalPermissions] = useState()

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <OptimizelyProvider>
          <GlobalContextProvider
            value={{
              preventNavigation,
              setPreventNavigation,
              selectedTab,
              setSelectedTab,
              globalPermissions,
              setGlobalPermissions,
            }}
          >
            <ChatClientProvider>
              <SnackbarProvider>
                <LoadingProvider>
                  <RouterProvider router={rootRouter} />
                </LoadingProvider>
              </SnackbarProvider>
            </ChatClientProvider>
          </GlobalContextProvider>
        </OptimizelyProvider>
      </QueryClientProvider>
    </ApolloProvider>
  )
}

export default App
