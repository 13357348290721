import { MenuItemType } from '@/components/Menu/types'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined'
import EditNotificationsOutlinedIcon from '@mui/icons-material/EditNotificationsOutlined'
import { PATHS_WITH_SECTIONS, getSubsectionLink } from '@/router/RoutesUtils'

export const ACCOUNT_SETTINGS_PAGES = {
  PERSONAL_INFORMATION: 'Personal Information',
  /** TODO: Uncomment when MEMBERSHIP page redesign with pricing is ready
   *  @see https://attorney.atlassian.net/browse/MP-1358
   */
  // MEMBERSHIP: 'Membership',
  CHANGE_PASSWORD: 'Change Password',
  NOTIFICATION_PREFERENCES: 'Notification Preferences',
  CHANGE_ACCOUNT_EMAIL: 'Change Account Email',
  INTEGRATIONS: 'Integrations',
  NEW_CASE_ALERTS: 'New Case Alerts',
}

export const getSubtitle = (page: string) => {
  switch (page) {
    case ACCOUNT_SETTINGS_PAGES.PERSONAL_INFORMATION: {
      return `Manage your contact details. This information will be used to send
          you updates about your account and platform.`
    }
    case ACCOUNT_SETTINGS_PAGES.CHANGE_PASSWORD: {
      return `Change your password to ensure the security of your account. If you
          have any questions or concerns, please don't hesitate to
          contact our support team.`
    }
    case ACCOUNT_SETTINGS_PAGES.NOTIFICATION_PREFERENCES: {
      return `Get notified whenever a new case is posted on the Marketplace so you don’t miss opportunities. Select which cases and how you want to be notified.`
    }
    case ACCOUNT_SETTINGS_PAGES.NEW_CASE_ALERTS: {
      return `Customize your real-time alerts when new cases are posted in the Marketplace.`
    }
    case ACCOUNT_SETTINGS_PAGES.CHANGE_ACCOUNT_EMAIL: {
      return `Manage the email address associated with your account. If you have
          any questions or concerns, please don't hesitate to contact our
          support team.`
    }
    default: {
      return ''
    }
  }
}

export const menuItems: MenuItemType[] = [
  {
    sectionTitle: '',
    items: [
      // { label: ACCOUNT_SETTINGS_PAGES.PERSONAL_INFORMATION, icon: <PersonOutlineOutlinedIcon /> },
      {
        label: ACCOUNT_SETTINGS_PAGES.CHANGE_PASSWORD,
        icon: <PersonOutlineOutlinedIcon />,
      },
      {
        label: ACCOUNT_SETTINGS_PAGES.NOTIFICATION_PREFERENCES,
        icon: <EditNotificationsOutlinedIcon />,
      },
      /** TODO: Uncomment when MEMBERSHIP page redesign with pricing is ready
       *  @see https://attorney.atlassian.net/browse/MP-1358
       */
      // {
      //   label: ACCOUNT_SETTINGS_PAGES.MEMBERSHIP,
      //   icon: <PersonOutlineOutlinedIcon />,
      // },

      // { label: ACCOUNT_SETTINGS_PAGES.CHANGE_ACCOUNT_EMAIL, icon: <PersonOutlineOutlinedIcon /> },
      {
        label: ACCOUNT_SETTINGS_PAGES.INTEGRATIONS,
        icon: <CompareArrowsOutlinedIcon />,
      },
    ],
  },
]

export const getAccountSettingsFirstLabel = () => menuItems[0].items[0].label
export const getAccountSettingsFirstLink = () =>
  getSubsectionLink(
    PATHS_WITH_SECTIONS.ACCOUNT_SETTINGS,
    getAccountSettingsFirstLabel()
  )
