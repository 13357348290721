import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

import JNotificationBanner from '@/components/atoms/JNotificationBanner'
import { useLoading } from '@/context/LoadingContext'
import ApplicationBar from '../AppBar'

interface ILayoutProps {
  children?: React.ReactNode
  sx?: SxProps<Theme>
  skipBottomBar?: boolean
}

export const pageLayoutBodyClass = 'body_layout'

const Layout: React.FC<ILayoutProps> = ({
  children,
  sx,
  skipBottomBar,
}: ILayoutProps) => {
  const { isLoading } = useLoading()

  return (
    <Box className="root_layout">
      <ApplicationBar skipBottomBar={skipBottomBar} />
      <Box
        className={pageLayoutBodyClass}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: theme => theme.palette.background.page,
          height: '100dvh',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <JNotificationBanner />
        {isLoading && (
          <LinearProgress
            color="success"
            sx={{
              width: '100%',
              position: 'relative',
              zIndex: 9999,
            }}
          />
        )}
        <Box
          maxWidth={{
            xs: '100%',
            md: 'xl',
          }}
          sx={{
            marginInline: {
              xs: 0,
              md: 'auto',
            },
          }}
        >
          <Box sx={Array.isArray(sx) ? sx : [sx]}>{children}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
