import { makeUseAxios } from 'axios-hooks'
import { AxiosError, isAxiosError } from 'axios'
import { createAxiosInstance } from './createAxiosInstance'

const API_URL = import.meta.env.VITE_API_URL
export const API_URL_ROOT = API_URL.replace('/graphql', '')

const request = createAxiosInstance({ baseURL: API_URL_ROOT })

export const useAxios = makeUseAxios({
  axios: request,
  defaultOptions: { manual: true },
})

export function assertAxiosError<T = never, D = never>(
  e: unknown
): asserts e is AxiosError<T, D> {
  isAxiosError<T, D>(e)
}

export default request
