import { StreamChat, Event, Channel, DefaultGenerics } from 'stream-chat'
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  ReactNode,
  useCallback,
} from 'react'
import { useUsersMeQuery } from '@/gql/systemApi'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES } from '@/constants'
import { useQuery } from '@apollo/client'
import { SignInQueryResult } from '@/apollo/AuthRoute'
import { IS_LOGGED_IN } from '@/apollo/queries'
import {
  ChannelOptions,
  IChannels,
  assertIsChannelOption,
  assertClient,
} from '@/components/GetStreamChat/types'

interface IChatClientContext {
  client: StreamChat | null
  messageDrawerOpen: boolean
  setMessageDrawerOpen: (open: boolean) => void
  selectedTab: ChannelOptions
  setSelectedTab: (tab: ChannelOptions) => void
  activeChannelId: string | null
  setActiveChannelId: (channelId: string | null) => void
  totalUnreadMessages: number
  setTotalUnreadMessages: React.Dispatch<React.SetStateAction<number>>
  unreadCaseMessages: number
  setUnreadCaseMessages: React.Dispatch<React.SetStateAction<number>>
  unreadDirectMessages: number
  setUnreadDirectMessages: React.Dispatch<React.SetStateAction<number>>
  connectUser: () => void
  disconnectUser: () => void
  expandedCases: Record<string, boolean>
  setExpandedCases: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >
  currentCaseChatId: string | null
  setCurrentCaseChatId: (caseChatId: string | null) => void
  closeMessageDrawer: () => void
  channels: IChannels
  setChannels: React.Dispatch<React.SetStateAction<IChannels>>
  channelsLoading: boolean
  setChannelsLoading: React.Dispatch<React.SetStateAction<boolean>>
  hasMoreChannels: boolean
  setHasMoreChannels: React.Dispatch<React.SetStateAction<boolean>>
}

const ChatClientContext = createContext<IChatClientContext>({
  client: null,
  messageDrawerOpen: false,
  setMessageDrawerOpen: () => {},
  selectedTab: ChannelOptions.DirectMessages,
  setSelectedTab: () => {},
  activeChannelId: null,
  setActiveChannelId: () => {},
  totalUnreadMessages: 0,
  setTotalUnreadMessages: () => {},
  unreadCaseMessages: 0,
  setUnreadCaseMessages: () => {},
  unreadDirectMessages: 0,
  setUnreadDirectMessages: () => {},
  connectUser: () => {},
  disconnectUser: () => {},
  expandedCases: {},
  setExpandedCases: () => {},
  currentCaseChatId: null,
  setCurrentCaseChatId: () => {},
  closeMessageDrawer: () => {},
  channels: {
    [ChannelOptions.CaseMessages]: [],
    [ChannelOptions.DirectMessages]: [],
  },
  setChannels: () => {},
  channelsLoading: false,
  setChannelsLoading: () => {},
  hasMoreChannels: true,
  setHasMoreChannels: () => {},
})

interface ChatClientProviderProps {
  children: ReactNode
}

const API_KEY = String(import.meta.env.VITE_STREAMCHAT_PUBLIC_KEY)

export const ChatClientProvider: React.FC<ChatClientProviderProps> = ({
  children,
}) => {
  // State variables
  const [client, setClient] = useState<StreamChat | null>(null)
  const [messageDrawerOpen, setMessageDrawerOpen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<ChannelOptions>(
    ChannelOptions.DirectMessages
  )
  const [activeChannelId, setActiveChannelId] = useState<string | null>(null)
  const [totalUnreadMessages, setTotalUnreadMessages] = useState<number>(0)
  const [unreadCaseMessages, setUnreadCaseMessages] = useState<number>(0)
  const [unreadDirectMessages, setUnreadDirectMessages] = useState<number>(0)
  const [expandedCases, setExpandedCases] = useState<Record<string, boolean>>(
    {}
  )
  const [currentCaseChatId, setCurrentCaseChatId] = useState<string | null>(
    null
  )
  const [channels, setChannels] = useState<IChatClientContext['channels']>({
    [ChannelOptions.CaseMessages]: [],
    [ChannelOptions.DirectMessages]: [],
  })
  const [channelsLoading, setChannelsLoading] = useState<boolean>(false)
  const [hasMoreChannels, setHasMoreChannels] = useState(true)

  const { data } = useQuery<SignInQueryResult>(IS_LOGGED_IN)
  const isLoggedIn = Boolean(data?.isLoggedIn)
  const skipQuery = !isLoggedIn

  // Fetch current user data
  const { refetch: refetchUserMe, data: currentUserData } = useUsersMeQuery({
    skip: skipQuery,
  })

  const getAllUnreadChannels = async () => {
    const streamChatClient = StreamChat.getInstance(API_KEY)
    const filter = {
      type: 'messaging',
      members: { $in: [currentUserData?.users_me?.id || ''] },
      has_unread: true,
    }

    const limit = 10
    let offset = 0
    let allChannels: Channel<DefaultGenerics>[] = []

    const fetchAllUnreadChannels = async (): Promise<
      Channel<DefaultGenerics>[]
    > => {
      const queriedChannels = await streamChatClient.queryChannels(
        filter,
        {},
        { limit, offset }
      )
      allChannels = [...allChannels, ...queriedChannels]

      if (queriedChannels.length < limit) {
        return allChannels
      }

      offset += limit
      return fetchAllUnreadChannels()
    }
    return fetchAllUnreadChannels()
  }

  const initUnreadMessages = async () => {
    const unreadChannels = await getAllUnreadChannels()
    const { unreadDirectCount, unreadCaseCount } = unreadChannels
      .filter(channel => !channel.data?.archived)
      .reduce(
        (acc, channel) => {
          if (channel.data?.channel_type === ChannelOptions.DirectMessages) {
            acc.unreadDirectCount += channel.state.unreadCount
          } else if (
            channel.data?.channel_type === ChannelOptions.CaseMessages
          ) {
            acc.unreadCaseCount += channel.state.unreadCount
          }

          return acc
        },
        { unreadDirectCount: 0, unreadCaseCount: 0 }
      )
    setUnreadDirectMessages(unreadDirectCount)
    setUnreadCaseMessages(unreadCaseCount)
    setTotalUnreadMessages(unreadCaseCount + unreadDirectCount)
  }

  const connectUser = useCallback(async () => {
    const refetchedUserData = await refetchUserMe()
    const refetchedUser = refetchedUserData?.data?.users_me
    const userProfile = refetchedUser?.attorneys?.[0]?.profiles?.[0]
    if (!refetchedUser) return

    const currentUserId = refetchedUser.id
    const fullName =
      `${userProfile?.first_name ?? refetchedUser.first_name} ${
        userProfile?.last_name ?? refetchedUser.last_name
      }`.trim() ?? ''
    const avatarImageId = refetchedUser.avatar?.id ?? ''
    const avatarPath = getImagePath({
      id: avatarImageId,
      size: IMAGE_SIZES.Medium,
    })
    const currentUserToken =
      refetchedUser.attorneys?.[0]?.stream_chat_keys?.[0]?.chat_token

    if (!currentUserId || !currentUserToken) {
      return
    }

    const newClient = StreamChat.getInstance(API_KEY)

    try {
      // Connect the user to the StreamChat client
      await newClient.connectUser(
        { id: currentUserId, name: fullName, image: avatarPath },
        currentUserToken
      )
      setClient(newClient)

      // Check URL params for an active channel ID and set state accordingly
      const urlParams = new URLSearchParams(window.location.search)
      const activeChannelIdParam = urlParams.get('activeChannelId')
      const hasActiveChannelIdParam = Boolean(activeChannelIdParam)
      if (hasActiveChannelIdParam) {
        setMessageDrawerOpen(true)
        setActiveChannelId(activeChannelIdParam)
      }
      await initUnreadMessages()
    } catch (error) {
      console.error('Failed to connect the user:', error)
    }
  }, [refetchUserMe])

  const closeMessageDrawer = useCallback(() => {
    setMessageDrawerOpen(false)
    setActiveChannelId(null)
    setCurrentCaseChatId(null)

    const url = new URL(window.location.href)
    url.searchParams.delete('activeChannelId')
    window.history.replaceState({}, '', url.toString())
  }, [setMessageDrawerOpen, setActiveChannelId, setCurrentCaseChatId])

  const disconnectUser = useCallback(() => {
    client?.disconnectUser()
    setClient(null)
    setActiveChannelId(null)
    closeMessageDrawer()
    setTotalUnreadMessages(0)
    setSelectedTab(ChannelOptions.DirectMessages)
  }, [client, setActiveChannelId, closeMessageDrawer])

  useEffect(() => {
    if (!skipQuery) {
      connectUser()
    }
  }, [
    refetchUserMe,
    setActiveChannelId,
    setMessageDrawerOpen,
    connectUser,
    skipQuery,
  ])

  const handleNewMessage = useCallback(
    async (event: Event) => {
      const { message, channel_id: channelId } = event
      const { id: messageUserId } = message?.user ?? {}

      assertClient(client)

      const [queriedChannel] = await client.queryChannels({
        id: { $eq: channelId || '' },
      })

      if (queriedChannel.data?.channel_type === ChannelOptions.DirectMessages) {
        const unreadDirectMsgs = unreadDirectMessages + 1
        setUnreadDirectMessages(unreadDirectMsgs)
      } else if (
        queriedChannel.data?.channel_type === ChannelOptions.CaseMessages
      ) {
        setUnreadCaseMessages(unreadCaseMessages + 1)
      }

      setTotalUnreadMessages(prevUnreadMessages => prevUnreadMessages + 1)

      if (messageUserId === currentUserData?.users_me?.id) return
      if (messageDrawerOpen && channelId === activeChannelId) return

      const channelType = queriedChannel.data?.channel_type
      assertIsChannelOption(channelType)

      const isChannelAlreadyLoaded = Object.values(channels).some(
        (channel: Channel<DefaultGenerics>[]) =>
          channel.some(({ cid }) => cid === queriedChannel.cid)
      )

      if (!isChannelAlreadyLoaded) {
        setChannels(prev => ({
          ...prev,
          [channelType]: [queriedChannel, ...prev[channelType]],
        }))
      }

      // Play notification sound for new messages
      try {
        const audio = new Audio('/new-message.mp3')
        audio.play()
      } catch (error) {
        console.warn('Failed to play the notification sound:', error)
      }
    },
    [
      activeChannelId,
      channels,
      client,
      currentUserData,
      messageDrawerOpen,
      unreadCaseMessages,
      unreadDirectMessages,
    ]
  )

  // Effect to subscribe to StreamChat events
  useEffect(() => {
    client?.on('notification.message_new', handleNewMessage)
    client?.on('message.new', handleNewMessage)

    return () => {
      client?.off('notification.message_new', handleNewMessage)
      client?.off('message.new', handleNewMessage)
    }
  }, [client, handleNewMessage])

  const providerValue = useMemo(
    () => ({
      client,
      messageDrawerOpen,
      setMessageDrawerOpen,
      selectedTab,
      setSelectedTab,
      activeChannelId,
      setActiveChannelId,
      totalUnreadMessages,
      setTotalUnreadMessages,
      unreadCaseMessages,
      setUnreadCaseMessages,
      unreadDirectMessages,
      setUnreadDirectMessages,
      connectUser,
      disconnectUser,
      expandedCases,
      setExpandedCases,
      currentCaseChatId,
      setCurrentCaseChatId,
      closeMessageDrawer,
      channels,
      setChannels,
      channelsLoading,
      setChannelsLoading,
      hasMoreChannels,
      setHasMoreChannels,
    }),
    [
      client,
      messageDrawerOpen,
      selectedTab,
      activeChannelId,
      totalUnreadMessages,
      unreadCaseMessages,
      setUnreadCaseMessages,
      unreadDirectMessages,
      setUnreadDirectMessages,
      connectUser,
      disconnectUser,
      expandedCases,
      setExpandedCases,
      currentCaseChatId,
      setCurrentCaseChatId,
      closeMessageDrawer,
      channels,
      channelsLoading,
      hasMoreChannels,
    ]
  )

  return (
    <ChatClientContext.Provider value={providerValue}>
      {children}
    </ChatClientContext.Provider>
  )
}

export const useChatClient = (): IChatClientContext =>
  useContext(ChatClientContext)
