import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'

import { ParameterName, bootParamsService } from '@/services/BootParams'
import InviteToNetworkModal from '../InviteToNetworkModal'

export const CallToAction: React.FC = () => {
  const theme = useTheme()
  const inviteModal = bootParamsService.get(
    ParameterName.INVITE_TO_NETWORK_MODAL
  )
  const [isModalOpen, setIsModalOpen] = useState(inviteModal === 'open')

  const handleInviteButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalSubmit = () => {
    setIsModalOpen(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'start', sm: 'center' },
        p: 3,
        borderRadius: 1,
        bgcolor: theme.palette.secondary.lightest,
        border: `1px solid ${theme.palette.divider}`,
        gap: 2,
      }}
    >
      <Box sx={{ mb: { xs: 2, md: 0 } }}>
        <Typography variant="h6" component="h2" fontSize={theme.spacing(1.75)}>
          Add your network to Attorney Share
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
          fontSize={theme.spacing(1.75)}
        >
          Bring your legal network to the platform and streamline your referrals
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleInviteButtonClick}
      >
        Invite Colleagues to Attorney Share
      </Button>

      <InviteToNetworkModal
        open={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </Box>
  )
}
