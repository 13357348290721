import { JAvatarV2 } from './v2'
import { JAvatarProps } from './types'

const JAvatar = (props: JAvatarProps) => {
  const { isVetted, ...rest } = props

  return <JAvatarV2 {...rest} isVetted={isVetted} />
}

export default JAvatar
