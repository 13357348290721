import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { JAvatarProps } from '../types'

const MAX_BADGES = 2

export const AVATAR_CONFIGS = {
  xs: {
    avatar: { width: 24, height: 24 },
    badge: { width: 12, height: 12 },
    fontSize: '7px',
    transform: 'translate(-10px, -5px)',
    ringWidth: 1,
  },
  sm: {
    avatar: { width: 32, height: 32 },
    badge: { width: 16, height: 16 },
    fontSize: '8px',
    transform: 'translate(-12px, -6px)',
    ringWidth: 1,
  },
  md: {
    avatar: { width: 40, height: 40 },
    badge: { width: 18, height: 18 },
    fontSize: '10px',
    transform: 'translate(-14px, -6px)',
    ringWidth: 1.5,
  },
  lg: {
    avatar: { width: 48, height: 48 },
    badge: { width: 20, height: 20 },
    fontSize: '11px',
    transform: 'translate(-16px, -8px)',
    ringWidth: 2,
  },
  xl: {
    avatar: { width: 64, height: 64 },
    badge: { width: 24, height: 24 },
    fontSize: '12px',
    transform: 'translate(-20px, -10px)',
    ringWidth: 2,
  },
  '2xl': {
    avatar: { width: 120, height: 120 },
    badge: { width: 32, height: 32 },
    fontSize: '14px',
    transform: 'translate(-30px, -15px)',
    ringWidth: 3,
  },
} as const

type AvatarSize = keyof typeof AVATAR_CONFIGS

interface BadgeIconProps {
  src: string
  alt: string
  config: (typeof AVATAR_CONFIGS)[AvatarSize]
  transform?: string
  zIndex: number
}

const BadgeIcon = ({
  src,
  alt,
  config,
  transform = undefined,
  zIndex,
}: BadgeIconProps) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    sx={{
      position: 'absolute',
      width: config.badge.width,
      height: config.badge.height,
      zIndex,
      objectFit: 'cover',
      borderRadius: '50%',
      transform,
      border: theme =>
        `${config.ringWidth}px solid ${theme.palette.common.white}`,
    }}
  />
)

interface SecondaryBadgeProps {
  config: (typeof AVATAR_CONFIGS)[AvatarSize]
  hasMoreAffiliations: boolean
  totalAffiliations: number
  secondBadge?: { badge_image_url?: string; name?: string }
}

const SecondaryBadge = ({
  config,
  hasMoreAffiliations,
  totalAffiliations,
  secondBadge,
}: SecondaryBadgeProps) => (
  <Box
    sx={{
      position: 'absolute',
      transform: config.transform,
      borderRadius: '50%',
      width: config.badge.width,
      height: config.badge.height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      ...((hasMoreAffiliations || secondBadge) && {
        border: theme =>
          `${config.ringWidth}px solid ${theme.palette.common.white}`,
      }),
      ...(hasMoreAffiliations && {
        backgroundColor: '#E8E8E8',
        color: 'text.disabled',
      }),
    }}
  >
    {hasMoreAffiliations ? (
      <Typography variant="caption" fontSize={config.fontSize}>
        +{totalAffiliations - 1}
      </Typography>
    ) : (
      secondBadge?.badge_image_url && (
        <Box
          component="img"
          src={secondBadge.badge_image_url}
          alt={secondBadge.name}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
          }}
        />
      )
    )}
  </Box>
)

export const JAvatarV2 = ({
  isQualified = false,
  isVetted = false,
  affiliations = [],
  size = 'sm',
  ...props
}: JAvatarProps) => {
  const config = AVATAR_CONFIGS[size]

  const sortedAffiliations = [...affiliations].sort(
    (a, b) => a.priority - b.priority
  )
  const hasMoreAffiliations = affiliations.length > MAX_BADGES
  const primaryBadge = sortedAffiliations[0]
  const secondaryBadge = sortedAffiliations[1]

  const badgeContent = primaryBadge && (
    <Tooltip
      title={
        <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
          {sortedAffiliations.map(aff => aff.name).join('\n')}
        </Typography>
      }
    >
      <Box
        sx={{
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          ...config.badge,
        }}
      >
        <BadgeIcon
          src={primaryBadge.badge_image_url}
          alt={primaryBadge.name}
          config={config}
          zIndex={2}
        />
        <SecondaryBadge
          config={config}
          hasMoreAffiliations={hasMoreAffiliations}
          totalAffiliations={affiliations.length}
          secondBadge={secondaryBadge}
        />
      </Box>
    </Tooltip>
  )

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={badgeContent}
      sx={{
        marginRight: 0,
      }}
    >
      <Avatar
        {...props}
        sx={{
          ...config.avatar,
          ...((isQualified || isVetted) && {
            border: theme => `3px solid ${theme.palette.background.badge}`,
          }),
        }}
        slotProps={{
          img: {
            loading: 'lazy',
          },
        }}
      />
    </Badge>
  )
}
